<template>
  <div style="padding: 20px 0;">
    <el-tabs v-model="activeName">
      <el-tab-pane label="询单信息" name="info">
        <infoCard @infoPlatform="infoPlatform"></infoCard>
      </el-tab-pane>
      <el-tab-pane label="报名信息" name="signUp">
        <div class="default-table">
          <br>
          <!--      报名表-->
          <el-card class="box-card">
            <inquiriesHead :tableData="tableData" :title="'报名表'"></inquiriesHead>
            <el-tabs v-model="tabActive" @tab-click="changeTab('bm')">
              <el-tab-pane :key="item" :label="item" :name="item" v-for="item in tabPlatforms">
                <el-table :data="tableInfoData"
                          @selection-change="handleSelectionChange('bm',$event)"
                          border
                          stripe
                          style="width: 100%">
                  <el-table-column
                      type="selection"
                      width="55">
                  </el-table-column>
                  <el-table-column
                      align="center"
                      label="序号"
                      type="index"
                      width="80">
                  </el-table-column>
                  <el-table-column
                      align="center"
                      key="ly"
                      label="录用状态说明"
                      min-width="180" prop="status"
                  >
                    <template slot-scope="{row}">
                      <el-row>
                        录用状态：
                        <el-tag v-if="row.employ_status === 2" type="success">已初选</el-tag>
                        <span v-else>已报名</span>
                        <el-tag style="margin-left: 10px" v-if="row.employ_status === 2&&row.confirm_status===2">已合作
                        </el-tag>
                      </el-row>
                      <el-row>
                        合作意向: <span style=" color: #409EFF" v-if="row.intention===1">试用</span>
                        <span style="color:#67C23A" v-if="row.intention===2">可接</span>
                        <span style="color: #F56C6C" v-if="row.intention===3">拒接</span>
                        {{ row.status === 3 ? row.reason : '' }}
                      </el-row>
                      <el-row>
                        意向说明：{{ row.intention_note || '暂无' }}
                      </el-row>
                      <el-row>
                        备注：{{ row.note || '暂无' }}
                      </el-row>
                    </template>
                  </el-table-column>
                  <el-table-column align="center" header-align="center" label="基本信息" width="270">
                    <template slot-scope="scope">
                                                <span class="isassign"
                                                      v-if="scope.row.is_assign===1&&scope.row.newDetails">定向</span>
                      <PanelKolInfo :data="scope.row.newDetails" :index="scope.$index"
                                    style="height: 100%"
                                    v-if="scope.row.newDetails"/>
                      <span v-else>
                    <el-tag type="danger"
                            v-if="scope.row.is_assign==1&&!scope.row.newDetails">定向</el-tag>
                    &nbsp;{{
                          scope.row.nickname
                        }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column header-align="center" label="其他信息" width="300">
                    <template slot-scope="{row}">
                      <PanelPlatformInfo :data="row.newDetails" v-if="row.newDetails"/>
                      <span v-else>暂无</span>
                    </template>
                  </el-table-column>
                  <el-table-column align="center" label="当月价格" min-width="280"
                                   prop="real_quote">
                    <template slot-scope="{row,column}">
                      <div class="price-div" v-if="row.newDetails">
                        <div class="edit-box">
                          <PriceGrids :rows="row.newDetails[column.property]"/>

                        </div>
                      </div>
                      <span v-else>暂无</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                      label="商务案例"
                      min-width="120"
                      prop="newDetails.bs_case"
                  >
                    <template slot-scope="{row}">
                      <el-card :body-style="{ height: '200px',overflow:'scroll' }" shadow="hover"
                               v-if="row.newDetails">
                        <div v-html="row.newDetails.bs_case"></div>
                      </el-card>
                      <span v-else>暂无</span>

                    </template>
                  </el-table-column>
                  <el-table-column
                      label="授权费用（参考）"
                      min-width="120"
                  >
                    <template slot-scope="{row}">
                      <div v-if="row.newDetails?.fans_total!==undefined">
                        <div v-for="item in empowerComp" :key="item.type">
                          <h4>
                            {{ item.type }}
                          </h4>
                          <span v-if="['抖音','快手'].includes(tabActive)">{{
                              item.data
                            }}</span>
                          <span v-if="['小红书','微博'].includes(tabActive)">
                        {{
                              row.newDetails?.fans_total && row.newDetails?.fans_total > 1000000 ? item.data['1000000'] : row.newDetails?.fans_total >= 500000 ? item.data['500000'] : row.newDetails?.fans_total >= 300000 ? item.data['300000'] : row.newDetails?.fans_total > 0 ? item.data['299999'] : '/'
                            }}
                    </span>
                        </div>
                      </div>
                      <span v-else>/</span>

                    </template>

                  </el-table-column>
                  <el-table-column align="center" fixed="right" label="操作" width="180">
                    <template slot-scope="{row}">
                      <!--                  changeStatus(row,'employ_status')-->
                      <!--                  row.employ_status-->
                      <el-row
                          v-if="(tableData.is_apply===1||tableData.status !== 2)&&userPermissions.indexOf('inquiry_employ') !== -1">
                        <el-col :span="12" v-if="row.employ_status===1">
                          <el-link @click="changeStatus(row,'employ_status')" icon="el-icon-medal"
                                   size="mini"
                                   type="primary">
                            <span>进入初选</span>
                          </el-link>
                        </el-col>
                        <el-col :span="12" v-else-if="row.confirm_status!==2">
                          <el-link @click="changeStatus(row,'employ_status')" icon="el-icon-sunrise"
                                   size="mini"
                                   type="primary"
                          >
                            <span>取消初选</span>
                          </el-link>
                        </el-col>
                      </el-row>
                    </template>
                  </el-table-column>

                </el-table>

              </el-tab-pane>
            </el-tabs>
            <br>
            <el-row>
              <div @click="exportData('bm')" class="text2"
                   v-if="userPermissions.indexOf('inquiry_export_1') !== -1">
                <i class="el-icon-download"></i>
                导出报名表
              </div>

            </el-row>
            <br>
            <el-row v-if="tableData.status!==2">
              <el-button
                  @click="handleVoid(1)"
                  size="big"
                  type="success"
                  v-if="tableData.is_apply===3">
                开始报名
              </el-button>
              <el-button
                  @click="handleVoid(3)"
                  size="big"
                  type="warning"
                  v-if="tableData.is_apply===1">
                结束报名
              </el-button>
              <el-button
                  @click="handleVoid(4)"
                  size="big"
                  type="danger"
                  v-show="tableData.status!==2&&userPermissions.indexOf('inquiry_finish') !== -1">
                项目完结
              </el-button>
              <el-button
                  @click="handleEmploy('employ')"
                  size="big"
                  type="success"
                  v-if="userPermissions.indexOf('inquiry_employ') !== -1">
                批量初选
              </el-button>
            </el-row>
            <el-row>
              <el-button
                  style="float:right;"
                  @click="handleVoid(5)"
                  size="big"
                  type="warning"
                  v-if="tableData.status===2&&userPermissions.indexOf('inquiry_revocation') !== -1">
                <i class="el-icon-refresh-left"></i>
                撤回
              </el-button>
            </el-row>

          </el-card>
          <br>
          <br>
          <!--      初选表-->
          <el-card class="box-card" id="employ" v-if="this.tableData?.employ_count>0">
            <inquiriesHead :tableData="tableData" :title="'初选表'"></inquiriesHead>
            <el-tabs v-model="tabActive" @tab-click="changeTab('employ')">
              <el-tab-pane :key="item" :label="item" :name="item" v-for="item in tabPlatforms">
                <el-table :data="employData"
                          border
                          stripe
                          @selection-change="handleSelectionChange('employ',$event)"
                          style="width: 100%">
                  <el-table-column
                      type="selection"
                      width="55">
                  </el-table-column>
                  <el-table-column
                      align="center"
                      label="序号"
                      type="index"
                      width="80">
                  </el-table-column>
                  <el-table-column
                      align="center"
                      key="ly"
                      label="录用状态说明"
                      min-width="180" prop="status"
                  >
                    <template slot-scope="{row}">
                      <el-row>
                        录用状态：
                        <el-tag type="success">已初选</el-tag>
                        <el-tag style="margin-left: 10px" v-if="row.employ_status === 2&&row.confirm_status===2">已合作
                        </el-tag>
                      </el-row>
                      <el-row>
                        合作意向: <span style=" color: #409EFF" v-if="row.intention===1">试用</span>
                        <span style="color:#67C23A" v-if="row.intention===2">可接</span>
                        <span style="color: #F56C6C" v-if="row.intention===3">拒接</span>
                        {{ row.status === 3 ? row.reason : '' }}
                      </el-row>
                      <el-row>
                        意向说明：{{ row.intention_note || '暂无' }}
                      </el-row>
                      <el-row>
                        备注：{{ row.note || '暂无' }}
                      </el-row>
                    </template>
                  </el-table-column>
                  <el-table-column align="center" header-align="center" label="基本信息" width="270">
                    <template slot-scope="scope">
                                          <span class="isassign"
                                                v-if="scope.row.is_assign===1&&scope.row.newDetails">定向</span>
                      <PanelKolInfo :data="scope.row.newDetails" :index="scope.$index"
                                    style="height: 100%"
                                    v-if="scope.row.newDetails"/>
                      <span v-else>  <el-tag type="danger"
                                             v-if="scope.row.is_assign==1&&!scope.row.newDetails">定向</el-tag>&nbsp;{{
                          scope.row.nickname
                        }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column header-align="center" label="其他信息" width="300">
                    <template slot-scope="scope">
                      <PanelPlatformInfo :data="scope.row.newDetails" v-if="scope.row.newDetails"/>
                      <span v-else>暂无</span>
                    </template>
                  </el-table-column>
                  <el-table-column align="center" label="当月价格" min-width="280"
                                   prop="real_quote">
                    <template slot-scope="{row,column}">
                      <div class="price-div" v-if="row.newDetails">
                        <div class="edit-box">
                          <PriceGrids :rows="row.newDetails[column.property]"/>

                        </div>
                      </div>
                      <span v-else>暂无</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                      label="商务案例"
                      min-width="120"
                      prop="newDetails.bs_case">
                    <template slot-scope="{row}">
                      <el-card :body-style="{ height: '200px',overflow:'scroll' }" shadow="hover"
                               v-if="row.newDetails">
                        <div v-html="row.newDetails.bs_case"></div>
                      </el-card>
                      <span v-else>暂无</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                      label="授权费用（参考）"
                      min-width="120"
                  >
                    <template slot-scope="{row}">
                      <div v-if="row.newDetails?.fans_total!==undefined">
                        <div v-for="item in empowerComp" :key="item.type">
                          <h4>
                            {{ item.type }}
                          </h4>
                          <span v-if="['抖音','快手'].includes(tabActive)">{{
                              item.data
                            }}</span>
                          <span v-if="['小红书','微博'].includes(tabActive)">
                        {{
                              row.newDetails?.fans_total && row.newDetails?.fans_total > 1000000 ? item.data['1000000'] : row.newDetails?.fans_total >= 500000 ? item.data['500000'] : row.newDetails?.fans_total >= 300000 ? item.data['300000'] : row.newDetails?.fans_total > 0 ? item.data['299999'] : '/'
                            }}
                    </span>
                        </div>

                      </div>
                      <span v-else>/</span>

                    </template>

                  </el-table-column>

                  <el-table-column
                      label="消息通知"
                      min-width="120"
                      prop="confirm_notify">
                    <template slot-scope="{row}">
                      <span v-if="row.employ_notify_count <= 0" style="color: blue">未通知 {{
                          row.apply_nickname
                        }}</span>
                      <span v-else style="color: #ff3176">已通知 {{
                          row.apply_nickname
                        }}{{ row.employ_notify_count }}次</span>
                    </template>
                  </el-table-column>
                  <el-table-column align="center" fixed="right" label="操作" width="180">
                    <template slot-scope="{row}">
                      <!--                  changeStatus(row,'employ_status')-->
                      <!--                  row.employ_status-->
                      <el-row
                          v-if="(tableData.is_apply===1||tableData.status !== 2)&&userPermissions.indexOf('inquiry_employ') !== -1">
                        <el-col :span="12" v-if="row.confirm_status!==2">
                          <el-link @click="changeStatus(row,'employ_status')" icon="el-icon-sunrise"
                                   size="mini"
                                   type="primary">
                            <span>取消初选</span>
                          </el-link>
                        </el-col>
                        <el-col :span="12" v-if="row.confirm_status===1">
                          <el-link @click="changeStatus(row,'confirm_status')" icon="el-icon-trophy"
                                   size="mini"
                                   type="primary">
                            <span>合作</span>
                          </el-link>
                        </el-col>
                        <el-col :span="12" v-else>
                          <el-link @click="changeStatus(row,'confirm_status')" icon="el-icon-sunrise"
                                   size="mini"
                                   type="primary"
                          >
                            <span>取消合作</span>
                          </el-link>
                        </el-col>
                      </el-row>
                    </template>
                  </el-table-column>

                </el-table>

              </el-tab-pane>
            </el-tabs>
            <br>
            <br>
            <el-row v-if="tableData.status!==2">
              <el-button
                  @click="handleEmploy('confirm')"
                  size="big"
                  type="success"
                  v-if="userPermissions.indexOf('inquiry_coop') !== -1">
                批量合作
              </el-button>
              <el-button
                  @click="employNotify"
                  size="big"
                  type="success"
                  v-if="userPermissions.indexOf('inquiry_employ_notify') !== -1"
              >
                初选通知{{ employ_ids.length > 0 ? employ_ids.length + '人' : '' }}
              </el-button>

            </el-row>
            <br>
            <el-row>
              <div @click="exportData('employ')" class="text2"
                   v-if="userPermissions.indexOf('inquiry_export_2') !== -1">
                <i class="el-icon-download"></i>
                导出初选表
              </div>
            </el-row>
          </el-card>

          <br>
          <br>
          <!--      合作表-->

          <el-card class="box-card" v-if="this.tableData?.confirm_count>0" id="confirm">
            <inquiriesHead :tableData="tableData" :title="'合作表'"></inquiriesHead>
            <el-tabs v-model="tabActive" @tab-click="changeTab('confirm')">
              <el-tab-pane :key="item" :label="item" :name="item" v-for="item in tabPlatforms">
                <el-table :data="confirmData"
                          @selection-change="handleSelectionChange('confirm',$event)"
                          border
                          stripe
                          style="width: 100%">
                  <el-table-column
                      type="selection"
                      width="55">
                  </el-table-column>
                  <el-table-column
                      align="center"
                      label="序号"
                      type="index"
                      width="80">
                  </el-table-column>
                  <el-table-column
                      align="center"
                      key="ly"
                      label="录用状态说明"
                      min-width="180" prop="status"
                  >
                    <template slot-scope="{row}">
                      <el-row>
                        录用状态：
                        <el-tag v-if="row.employ_status === 2&&row.confirm_status===2">已合作
                        </el-tag>
                      </el-row>
                      <el-row>
                        合作意向: <span style=" color: #409EFF" v-if="row.intention===1">试用</span>
                        <span style="color:#67C23A" v-if="row.intention===2">可接</span>
                        <span style="color: #F56C6C" v-if="row.intention===3">拒接</span>
                        {{ row.status === 3 ? row.reason : '' }}
                      </el-row>
                      <el-row>
                        意向说明：{{ row.intention_note || '暂无' }}
                      </el-row>
                      <el-row>
                        备注：{{ row.note || '暂无' }}
                      </el-row>
                    </template>
                  </el-table-column>
                  <el-table-column label="合作意向" width="80">
                    <template slot-scope="{row}">
                      <span style="color: blue" v-if="row.intention===1">试用</span>
                      <span style="color: green" v-if="row.intention===2">可接</span>
                      <span style="color: red" v-if="row.intention===3">拒接</span>
                      {{ row.status === 3 ? row.reason : '' }}
                    </template>
                  </el-table-column>
                  <el-table-column
                      label="意向说明"
                      prop="intention_note">
                  </el-table-column>
                  <el-table-column align="center" header-align="center" label="基本信息" width="270">
                    <template slot-scope="scope">
                                          <span class="isassign"
                                                v-if="scope.row.is_assign===1&&scope.row.newDetails">定向</span>
                      <PanelKolInfo :data="scope.row.newDetails" :index="scope.$index"
                                    style="height: 100%"
                                    v-if="scope.row.newDetails"/>
                      <span v-else>
                                              <el-tag type="danger"
                                                      v-if="scope.row.is_assign==1&&!scope.row.newDetails">定向</el-tag>&nbsp;{{
                          scope.row.nickname
                        }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column header-align="center" label="其他信息" width="300">
                    <template slot-scope="scope">
                      <PanelPlatformInfo :data="scope.row.newDetails" v-if="scope.row.newDetails"/>
                      <span v-else>暂无</span>
                    </template>
                  </el-table-column>
                  <el-table-column align="center" label="当月价格" min-width="280"
                                   prop="real_quote">
                    <template slot-scope="{row,column}">
                      <div class="price-div" v-if="row.newDetails">
                        <div class="edit-box">
                          <PriceGrids :rows="row.newDetails[column.property]"/>

                        </div>
                      </div>
                      <span v-else>暂无</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                      label="商务案例"
                      min-width="120"
                      prop="newDetails.bs_case">
                    <template slot-scope="{row}">
                      <el-card :body-style="{ height: '200px',overflow:'scroll' }" shadow="hover"
                               v-if="row.newDetails">
                        <div v-html="row.newDetails.bs_case"></div>
                      </el-card>
                      <span v-else>暂无</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                      label="授权费用（参考）"
                      min-width="120"
                  >
                    <template slot-scope="{row}">
                      <div v-if="row.newDetails?.fans_total!==undefined">
                        <div v-for="item in empowerComp" :key="item.type">
                          <h4>
                            {{ item.type }}
                          </h4>
                          <span v-if="['抖音','快手'].includes(tabActive)">{{
                              item.data
                            }}</span>
                          <span v-if="['小红书','微博'].includes(tabActive)">
                        {{
                              row.newDetails?.fans_total && row.newDetails?.fans_total > 1000000 ? item.data['1000000'] : row.newDetails?.fans_total >= 500000 ? item.data['500000'] : row.newDetails?.fans_total >= 300000 ? item.data['300000'] : row.newDetails?.fans_total > 0 ? item.data['299999'] : '/'
                            }}
                    </span>
                        </div>
                      </div>
                      <span v-else>/</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                      label="消息通知"
                      min-width="120"
                      prop="confirm_notify">
                    <template slot-scope="{row}">
                  <span v-if="row.confirm_notify_count <= 0" style="color: blue">未通知 {{
                      row.apply_nickname
                    }}</span>
                      <span v-else style="color: #ff3176">已通知 {{
                          row.apply_nickname
                        }}{{ row.confirm_notify_count }}次</span>
                    </template>
                  </el-table-column>
                  <el-table-column align="center" fixed="right" label="操作" width="180">
                    <template slot-scope="{row}">
                      <!--                  changeStatus(row,'employ_status')-->
                      <!--                  row.employ_status-->
                      <el-row
                          v-if="(tableData.is_apply===1||tableData.status !== 2)&&userPermissions.indexOf('inquiry_employ') !== -1">
                        <el-col :span="12" v-if="row.confirm_status===1">
                          <el-link @click="changeStatus(row,'confirm_status')" icon="el-icon-trophy"
                                   size="mini"
                                   type="primary">
                            <span>合作</span>
                          </el-link>
                        </el-col>
                        <el-col :span="12" v-else>
                          <el-link @click="changeStatus(row,'confirm_status')" icon="el-icon-sunrise"
                                   size="mini"
                                   type="primary"
                          >
                            <span>取消合作</span>
                          </el-link>
                        </el-col>
                      </el-row>
                    </template>
                  </el-table-column>
                </el-table>
              </el-tab-pane>
            </el-tabs>
            <br>
            <el-row v-if="tableData.status!==2">
              <el-button
                  @click="handleVoid(4)"
                  size="big"
                  type="danger"
                  v-show="tableData.status!==2&&userPermissions.indexOf('inquiry_finish') !== -1">
                项目完结
              </el-button>
              <el-button
                  @click="confirmNotify"
                  size="big"
                  type="success"
                  v-if="confirmData.length>0&&userPermissions.indexOf('inquiry_confirm_notify') !== -1">
                合作通知{{ confirm_ids.length > 0 ? confirm_ids.length + '人' : '' }}
              </el-button>
            </el-row>
            <br>
            <el-row>
              <div @click="exportData('confirm')" class="text2"
                   v-if="userPermissions.indexOf('inquiry_export_3') !== -1">
                <i class="el-icon-download"></i>
                导出合作表
              </div>
              <el-button
                  style="float:right;"
                  @click="handleVoid(5)"
                  size="big" 红红火火恍恍惚惚哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈
                  type="warning"
                  v-if="tableData.status===2&&userPermissions.indexOf('inquiry_revocation') !== -1">
                <i class="el-icon-refresh-left"></i>
                撤回
              </el-button>
            </el-row>
          </el-card>
          <br>
          <br>
          <el-card v-if="userPermissions.indexOf('inquiry_project_log') !== -1"
                   style="max-height: 500px;overflow-y: scroll">
            <h3 class="text">操作记录</h3>
            <el-link style="margin: 10px 0" type="primary" @click="logShow=!logShow">{{
                logShow ? '展开' : '收起'
              }}
            </el-link>
            <br>
            <el-timeline class="opt-log" v-if="logShow">
              <el-timeline-item
                  :color="activity.color"
                  :key="index"
                  v-for="(activity, index) in divideLog"
              >
                {{ activity.created_at }}
                &nbsp; &nbsp; {{ activity.msg }}
              </el-timeline-item>
            </el-timeline>
          </el-card>
        </div>
      </el-tab-pane>
    </el-tabs>
    <br>
    <br>
  </div>
</template>

<script>
import PriceGrids from '@/pages/publication/PriceGrids'
import infoCard from './components/infoCard'
import FileSaver from 'file-saver'
import PanelKolInfo from './components/PanelKolInfo'
import PanelPlatformInfo from './components/PanelPlatformInfo'
import { mapGetters } from 'vuex'
import inquiriesHead from './components/inquiriesHead'
import ExportLink from '@/components/export/ExportLink'
// import empower from './index.json'

export default {
  name: 'InfoDetail',
  components: {
    ExportLink,
    PriceGrids, infoCard, PanelKolInfo, PanelPlatformInfo, inquiriesHead
  },
  data() {
    return {
      infoData: {
        name: '项目名',
        brand: '品牌名',
        product: '产品名',
        intro: '产品资料',
        platform: '平台',
        nickname: '定向红人',
        is_platform_pay: '是否合作',
        coop_id: '合作形式',
        is_send_back: 1,//是否寄回
        is_cart: false,//是否挂车
        cart_rate: '10%',
        is_auth_price: '平台授权',
        auth_price: '授权平台价',
        expect_start: '2022-2-10',
        except_end: '2022-10-1',
        apply_end: '2022-2-10',
        apply_start: '2022-10-1',
        is_assign: 1//是否定向
      },
      projectId: '',
      loadingStatus: false,
      tableData: {},
      employtable: {},
      confirmtable: {},
      infoPlatforms: [],
      employIds: [],
      employ_ids: [],
      confirm_ids: [],
      divideLog: [],
      tabPlatforms: [],
      //报名表
      tableInfoData: [],
      //初选表
      employData: [],
      //合作表
      confirmData: [],
      stepActive: 0,
      tabActive: '',
      chargingList: {},
      empowerComp: {},
      empowerData: {},
      placeData: [
        { name: '抖音', value: 'douyin' },
        { name: '小红书', value: 'xiaohongshu' },
        { name: '微博', value: 'weibo' },
        { name: '快手', value: 'kuaishou' }],
      activeName: 'info',
      logShow: false
    }
  },
  computed: {
    ...mapGetters(['userPermissions', 'userInfo'])
    // empowerComp() {
    //   console.log(this.chargingList)
    //   let data = this.chargingList[this.tabActive]
    //   console.log(data)
    //   return data
    // }

  },
  methods: {
    formatter(row) {
      if (row?.newDetails?.fans_total == undefined) {
        return '/'
      } else {
        let returnData = ''
        let returnArray = []
        if (this.empowerComp.length > 0) {
          this.empowerComp.forEach((item) => {
                returnArray.push(item.type)
                // returnData=`<h4>${item.type}</h4>`
                // console.log(this.tabActive)
                // if (['抖音', '快手'].includes(this.tabActive)) {
                //   returnData = `<h4>${item.type}</h4><span>${item.data}</span>`
                // }
                // if (['小红书', '微博'].includes(this.tabActive)) {
                //   let fas = 0
                //   if (row.newDetails.fans_total >= 1000000) {
                //     fas = 1000000
                //   } else if (500000 <= row.newDetails.fans_total < 1000000) {
                //     fas = 500000
                //   } else if (300000 <= row.newDetails.fans_total < 500000) {
                //     fas = 300000
                //   } else if (299999 <= row.newDetails.fans_total < 1) {
                //     fas = 299999
                //   }
                //   item.data.forEach((i) => {
                //     returnData = `<span>${this.empowerComp[index].type}</span><h1>${i[fas]}</h1>`
                //   })
                // }
              }
          )
        }
        return returnData
      }


      // return 'aa'
    }
    ,
    employNotify() {
      this.$confirm(`确定发送初选通知`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        //  artist_ids
        // "status": 2 //1:进行中 2:已完结 3:已作废/ 4：彻底完结
        let id = await this.$api.employNotify({ project_id: this.projectId, artist_ids: this.employ_ids })
        if (id) {
          this.$notify.success('通知成功')
          await this.getInfo()
          // location.reload()
        }
      }).catch(() => {
            this.$notify.info('已取消')
          }
      )
    },
    confirmNotify() {
      this.$confirm(`确定发送合作通知`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        // "status": 2 //1:进行中 2:已完结 3:已作废/ 4：彻底完结
        let id = await this.$api.confirmNotify({ project_id: this.projectId, artist_ids: this.confirm_ids })
        if (id) {
          this.$notify.success('通知成功')
          await this.getInfo()

          // location.reload()
        }
      }).catch(() => {
            this.$notify.info('已取消')
          }
      )
    },
    selectable(row) {
      return row.employ_status === 1
    },
    selectableConfirm(row) {
      return row.confirm_status === 1
    },
    changeTab() {
      //切换平台
      if (this.tableData?.details?.length > 0) {
        this.tableInfoData = this.tableData.details.filter(item => (item.platform_name) === this.tabActive)
      }
      // console.log(this.tabActive)
      if (this.employtable?.details?.length > 0) {
        this.employData = this.employtable?.details.filter(item => (item.platform_name) === this.tabActive)
      }
      if (this.confirmtable?.details?.length > 0) {
        this.confirmData = this.confirmtable?.details.filter(item => (item.platform_name) === this.tabActive)
      }
      this.getCharging()
      this.formatter()
      sessionStorage.setItem('isTab', this.tabActive)
    },
    infoPlatform(val) {
      this.infoPlatforms = val
      if (val?.length > 0) {
        val.forEach((item) => {
          this.tabPlatforms.push(item.name)
        })
      }
      this.tabActive = this.tabPlatforms?.[0]

      this.changeTab()
      sessionStorage.setItem('isTab', this.tabActive)

    }
    ,
    async handleVoid(status) {
      let msg = ''
      let endMsg = ''
      let statusData = {}
      switch (status) {
        case 1:
          msg = '开启报名？'
          endMsg = '开始报名切换成功'
          statusData = {
            id: this.projectId,
            status: 1,
            is_apply: status
          }
          break
        case 3:
          msg = '结束报名？'
          endMsg = '结束报名切换成功'
          statusData = {
            id: this.projectId,
            status: 1,
            is_apply: status
          }
          break
        case 4:
          msg = '完结项目？'
          endMsg = '完结项目切换成功'
          statusData = {
            id: this.projectId,
            status: 2
          }
          break
        case 5:
          msg = '撤回项目？'
          endMsg = '撤回项目切换成功'
          statusData = {
            id: this.projectId,
            status: 1
          }
          break
      }

      if (status !== 4) {
        this.$confirm(`确定是否${msg}`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          // "status": 2 //1:进行中 2:已完结 3:已作废/ 4：彻底完结
          let id = await this.$api.switchInquiryStatus(statusData)
          if (id) {
            this.$notify.success(endMsg)
            await this.getInfo()
          }
        })
      } else {
        this.$confirm(`确定是否${msg}`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          // "status": 2 //1:进行中 2:已完结 3:已作废/ 4：彻底完结
          let id = await this.$api.endInquiryStatus(statusData)
          if (id) {
            this.$notify.success(endMsg)
            await this.getInfo()
          }
        })
      }
    }
    ,
    handleSelectionChange(type, val) {
      this.employIds = []

      if (type === 'bm') {
        val.forEach((item) => {
          this.employIds.push(item.id)
        })
      } else if (type === 'employ') {
        this.employ_ids = []
        val.forEach((item) => {
          this.employIds.push(item.id)
          this.employ_ids.push(item.artist_id)
        })
      } else if (type === 'confirm') {
        this.confirm_ids = []
        val.forEach((item) => {
          this.employIds.push(item.id)
          this.confirm_ids.push(item.artist_id)
        })
      }

      // this.apply_ids = []

    },
    changeStatus(val, type) {
      let msg = ''
      let endMsg = ''
      let searchCondition = {}
      let scrollId = ''
      switch (type) {
        case 'employ_status':
          if (val.employ_status === 1) {
            msg = '进入初选'
            endMsg = '进入初选成功'
            searchCondition = {
              detail_id: val.id,
              project_id: this.projectId,
              employ_status: 2
            }
          } else {
            msg = '取消初选'
            endMsg = '取消初选成功'
            searchCondition = {
              detail_id: val.id,
              project_id: this.projectId,
              employ_status: 1
            }
          }
          break
        case 'confirm_status' :
          if (val.confirm_status === 1) {
            msg = '合作'
            endMsg = '合作成功'
            searchCondition = {
              detail_id: val.id,
              project_id: this.projectId,
              confirm_status: 2
            }
            scrollId = 'employ'

          } else {
            msg = '取消合作'
            endMsg = '取消合作成功'
            searchCondition = {
              detail_id: val.id,
              project_id: this.projectId,
              confirm_status: 1
            }
            scrollId = 'confirm'

          }
          break
      }

      this.$confirm(`确认${val.nickname}【${msg}】?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let data = ''
        if (type === 'employ_status') {
          data = await this.$api.cancelStatus(searchCondition)
        } else if (type === 'confirm_status') {
          data = await this.$api.singleConfirm(searchCondition)
        }
        //
        if (data !== '') {
          this.$notify.success(endMsg)
          setTimeout(() => {
            window.document.getElementById(scrollId).scrollIntoView()
          }, 1000)

          this.loadingStatus = true
          // location.reload()
          await this.getInfo()
        } else this.$notify.error('失败')
      }).catch(async () => {
        this.$notify.info('已取消')
        await this.getInfo()
      })
    }
    ,
    handleEmploy(type) {
      if (this.employIds.length <= 0) {
        this.$notify.error('请选择录用人')
        return false
      }
      // confirmApply
      this.$confirm(`确认批量录用${type === 'employ' ? '初选' : type === 'confirm' ? '合作' : ''}?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let searchCondition
        let data = ''
        if (type === 'employ') {
          searchCondition = { ids: this.employIds, project_id: this.projectId, employ_status: 2 }
          data = await this.$api.employApply(searchCondition)
        } else if (type === 'confirm') {
          searchCondition = { ids: this.employIds, project_id: this.projectId, confirm_status: 2 }
          data = await this.$api.confirmApply(searchCondition)
        }
        if (data !== '') {
          this.$notify.success('录用成功')
          await this.getInfo()
          this.loadingStatus = true
        } else this.$notify.error('失败')
      }).catch(async () => {
        this.$notify.info('已取消')
        await this.getInfo()
      })
    }
    ,
    async getCharging() {
      // let { list } = await this.$api.chargingList()
      // this.empowerData = list
      let tabActive = ''
      this.placeData.forEach(item => {
        item.name === this.tabActive ? tabActive = item : ''
      })
      if (this.empowerData.length > 0) {
        this.empowerData.forEach((item) => {
          if (item.type == tabActive.value) {
            this.empowerComp = item.content[tabActive.value]
          }
        })
      }


    }
    ,
    async getInfo() {
      this.tableData = {}
      this.loadingStatus = true
      let data = await this.$api.applyDetailInquiryList({ project_id: this.projectId })

      this.formatData(data)
      this.tableData = data
      this.tableInfoData = this.tableData.details.filter(item => (item.platform_name) === this.tabActive)
      //获取授权费用
      let { list } = await this.$api.chargingList()
      this.empowerData = list
      await this.getCharging()
      // this.tableData.confirm_count > 0 ? this.stepActive = 3 : this.tableData.employ_count > 0 ? this.stepActive = 2 : this.stepActive = 1
      if (this.tableData.employ_count > 0) {
        await this.getEmploy()
      }
      if (this.tableData.confirm_count > 0) {
        await this.getConfirm()
      }
      await this.getlogsInquiry()
      this.loadingStatus = false
    }
    ,
    async getEmploy() {
      this.employtable = {}
      this.loadingStatus = true
      let searchCondition = { project_id: this.projectId, employ_status: 2 }
      let data = await this.$api.applyDetailInquiryList(searchCondition)
      this.formatData(data)
      // this.employData = data.details
      this.employtable = data
      this.employData = this.employtable?.details.filter(item => (item.platform_name) === this.tabActive)
      this.loadingStatus = false
    }
    ,
    async getConfirm() {
      this.confirmtable = {}
      this.loadingStatus = true
      let searchCondition = { project_id: this.projectId, confirm_status: 2 }
      let data = await this.$api.applyDetailInquiryList(searchCondition)
      this.formatData(data)
      // this.confirmData = data.details
      this.confirmtable = data
      this.confirmData = this.confirmtable?.details.filter(item => (item.platform_name) === this.tabActive)
      this.loadingStatus = false
    },
    formatData(data) {
      //初始化data
      let newDetails = {}
      data.details.forEach((item) => {
        if (item.publications?.length > 0) {
          item.publications.forEach((i) => {
            if (item.platform_code === i.platform_code) {
              newDetails = i
              item.newDetails = newDetails
            }
          })
        } else {
          newDetails = {
            intro: '',
            skin_type: '',
            kol_label: '',
            city: '',
            fans_total: '',
            zc_total: '',
            real_quote: [],
            account_id: '',
            home_page_link: '',
            is_partner: '',
            opened_cart: '',
            bs_case: ''
          }
        }
      })
    }
    ,

    async exportData(type) {
      let searchCondition = {}
      if (type === 'bm') {
        searchCondition = { project_id: this.projectId }
      } else if (type === 'employ') {
        searchCondition = { project_id: this.projectId, employ_status: 2 }
      } else if (type === 'confirm') {
        searchCondition = { project_id: this.projectId, confirm_status: 2 }
      }
      let res = await this.$api.canApplyDetail(searchCondition)
      if (res === undefined) {
        this.$message.warning('该项目暂无录用数据')
      } else {
        try {
          let isFileSaverSupported = !!new Blob
          if (isFileSaverSupported) {
            // let searchCondition = { project_id: this.projectId, status: 2 }
            let response = await this.$api.exportApplyDetail(searchCondition)
            let data = response.data
            let contentType = response.headers['content-type'] || ''

            // let name = `${this.tableData.project_name}_${this.tableData.expect_start}至${this.tableData.except_end}_${type === 'bm' ? '报名表' : type === 'employ' ? '初选表' : '合作表'}`
            let name = `${this.tableData.project_name}_${this.tableData.expect_start}至${this.tableData.except_end}_${type === 'bm' ? '报名表' : type === 'employ' ? '初选表' : '合作表'}`
            let blob = new Blob([data], {
              type: contentType
            })

            // name='demo:80%'// 欧莱雅母亲节礼盒（80%复颜水乳礼盒，20%黑精华礼盒）_2023-04-03至2023-04-04_报名表
            FileSaver.saveAs(blob, decodeURI(encodeURI(name) + '.xlsx'), { autoBom: true })
          } else {
            this.$message.warning('浏览器不支持导出文件')
          }
        } catch (e) {
          this.$message.warning('导出异常，请联系管理员')
          // alert(JSON.stringify(e))
        }
      }
    }
    ,
    async getlogsInquiry() {
      if (this.userPermissions.indexOf('inquiry_project_log') !== -1) {
        this.divideLog = await this.$api.logsInquiry({ project_id: this.projectId })
      }
    }
  }
  ,
  mounted() {
    this.projectId = this.$route.params.id
    sessionStorage.setItem('isTab', 'null')
    this.getInfo()
    this.getCharging()
  }
}
</script>
<style scoped>
.el-steps >>> .el-step {
  cursor: pointer !important;
}
</style>
<style lang="scss">
.newForm {
  /*border: 1px solid red;*/
  .el-form-item__label {
    color: black;
    font-weight: 600;
  }

  .el-form-item--mini.el-form-item {
    margin-bottom: 13px;
  }
}

.title {
  font-size: 18px;
  font-weight: 800;
}

.download {
  color: #ff3176;
  margin-left: 10px;
  cursor: pointer;
}

.row {
  > h3 {
    display: flex;
    float: left;
    margin-right: 80px;
    text-align: center;
  }
}


</style>
<style lang="scss" scoped>

.text {
  font-size: 20px;
  background: -webkit-linear-gradient(-90deg, #e97ca1 30%, #ff3176);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.isassign {
  font-size: 40px;
  display: inline-block;
  transform: rotate(45deg);
  z-index: 99;
  position: absolute;
  right: 0;
  top: 30px;
  background-color: #ffeaf1;
  background-size: 120%;
  color: #ff3176;
}

.text2 {
  /*width: 100px;*/
  height: 40px;
  border-radius: 4px;
  text-align: center;
  /*font-size: 30px;*/
  color: white;
  background: #ff3176;
  /*background-color:#e97ca1 ;*/
  font-size: 14px;
  line-height: 40px;
  cursor: pointer;
  margin-bottom: 10px;
  transition-delay: 1s;
  float: left;
  margin-right: 10px;
  padding: 0 10px;

}

.text2:hover {
  background-image: linear-gradient(to left, #ebb5c7, #ff3176); /*-webkit-text-fill-color: transparent;*/
}
</style>
